import React, { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { graphql, useStaticQuery } from 'gatsby';

import withI18n from '../utils/hocs';
import SEO from '../components/SEO';
import Layout from '../components/Layout';
import Hero from '../components/Hero';
import ImageAndTextSection from '../components/ImageAndTextSection';

import styles from './development.module.scss';

import ogImage from '../images/fb-image.png';
import ogImageEn from '../images/fb-image_en.png';

function encode(data: Record<string, string>) {
  return Object.keys(data)
    .map(function (key) {
      return encodeURIComponent(key) + '=' + encodeURIComponent(data[key]);
    })
    .join('&');
}

const DevelopmentPage = (): React.ReactElement => {
  const { t, i18n } = useTranslation();
  const data = useStaticQuery(graphql`
    {
      heroImage: file(relativePath: { eq: "development/hero-bg.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
      ourGoal: file(relativePath: { eq: "development/our-goal.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 500) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
      ourStack: file(relativePath: { eq: "development/our-stack.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 500) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
      ourTerms: file(relativePath: { eq: "development/our-terms.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 500) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
    }
  `);

  const [selectedPlan, setSelectedPlan] = useState('personalizado');
  const [name, setName] = useState('');
  const [nameError, setNameError] = useState('');
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState('');
  const [message, setMessage] = useState('');
  const [messageError, setMessageError] = useState('');
  const [formError, setFormError] = useState('');
  const [submitted, setSubmitted] = useState(false);

  async function submitForm(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    e.preventDefault();
    let isValidForm = true;
    if (!name) {
      setNameError(
        t('development.form.error_name_required', 'O campo do nome deverá ser preenchido.'),
      );
      isValidForm = false;
    }
    if (!email) {
      setEmailError(
        t('development.form.error_email_required', 'O campo do email deverá ser preenchido.'),
      );
      isValidForm = false;
    } else if (!email.match(/^\S+@\S+\.\S+$/)) {
      setEmailError(
        t(
          'development.form.error_email_invalid',
          'Formato de email inválido (ex. exemplo@mail.pt)',
        ),
      );
      isValidForm = false;
    }
    if (!message) {
      setMessageError(
        t('development.form.error_message_required', 'O campo da mensagem deverá ser preenchido.'),
      );
      isValidForm = false;
    }

    if (isValidForm) {
      try {
        await fetch('/', {
          method: 'POST',
          headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
          body: encode({
            'form-name': 'hours',
            hours: selectedPlan,
            name: name,
            email: email,
            message: message,
          }),
        });
        setSubmitted(true);
      } catch (err) {
        console.error(err);
        setFormError(
          t(
            'development.form.error_unknown',
            'Ocorreu um problema no envio da sua mensagem, tente mais tarde.',
          ),
        );
      }
    }
  }

  return (
    <Layout>
      <SEO
        title={t('SEO.development.title', 'Pacotes de desenvolvimento - Mosca Digital')}
        description={t(
          'SEO.development.description',
          'Diferentes soluções para diferentes necessidades',
        )}
        url={'https://moscadigital.pt' + (i18n.language === 'en' ? '/en' : '') + '/development'}
        imageUrl={i18n.language === 'en' ? ogImageEn : ogImage}
      />
      <Hero
        withLogo
        bgImageFluid={data.heroImage.childImageSharp.fluid}
        title={t('development.title', 'Pacotes de desenvolvimento')}
        subtitle={t('development.description', 'Diferentes soluções para diferentes necessidades')}
      />

      <div style={{ display: 'none' }} id="hidden-content" className={styles.hiddenContent}>
        <div className="contact_form">
          <form
            id="contact-form"
            name="hours"
            data-netlify="true"
            data-netlify-honeypot="hidden-field"
          >
            <input
              className="hidden"
              name="hidden-field"
              placeholder="language"
              autoComplete="language"
              type="text"
            />
            <div className="form-group animated fadeInRight" data-animation="fadeInRight">
              <div>
                <label>{t('development.form.select_package', 'Selecionar pacote')}</label>
                <div className="form-group">
                  <div className="form-select">
                    <select
                      id="hours"
                      className="form-control"
                      name="hours"
                      data-required=""
                      disabled={submitted}
                      value={selectedPlan}
                      onChange={e => {
                        setSelectedPlan(e.target.value);
                        setFormError('');
                      }}
                    >
                      <option id="hours5" value="5">
                        {t('development.5_hours', '5 horas')}
                      </option>
                      <option id="hours20" value="20">
                        {t('development.20_hours', '20 horas')}
                      </option>
                      <option id="hours50" value="50">
                        {t('development.50_hours', '50 horas')}
                      </option>
                      <option id="hours100" value="100">
                        {t('development.100_hours', '100 horas')}
                      </option>
                      <option id="personalizado" value="personalizado">
                        {t('development.form.custom_hours', 'Personalizado')}
                      </option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
            <div
              className={'form-group animated fadeInRight' + (nameError ? ' has-error' : '')}
              data-animation="fadeInRight"
            >
              <label>{t('development.form.label_name', 'Nome')}</label>
              <input
                id="name"
                className="form-control"
                autoComplete="given-name"
                type="text"
                name="name"
                data-required=""
                disabled={submitted}
                value={name}
                onChange={e => {
                  setName(e.target.value);
                  setNameError('');
                  setFormError('');
                }}
              />
              <p id="name-error" className="text-danger">
                {nameError}
              </p>
            </div>
            <div
              className={'form-group animated fadeInRight' + (emailError ? ' has-error' : '')}
              data-animation="fadeInRight"
            >
              <label>{t('development.form.label_email', 'Email')}</label>
              <input
                id="email"
                className="form-control"
                type="email"
                autoComplete="email"
                name="email"
                data-required=""
                disabled={submitted}
                value={email}
                onChange={e => {
                  setEmail(e.target.value);
                  setEmailError('');
                  setFormError('');
                }}
              />
              <p id="email-error" className="text-danger">
                {emailError}
              </p>
            </div>
            <div
              className={'form-group animated fadeInRight' + (messageError ? ' has-error' : '')}
              data-animation="fadeInRight"
            >
              <label>{t('development.form.label_message', 'Mensagem')}</label>
              <textarea
                id="form-message"
                className="form-control"
                name="message"
                data-required=""
                disabled={submitted}
                value={message}
                onChange={e => {
                  setMessage(e.target.value);
                  setMessageError('');
                  setFormError('');
                }}
              ></textarea>
              <p id="form-message-error" className="text-danger">
                {messageError}
              </p>
            </div>
            <div className="form-group mt-25 animated fadeInRight" data-animation="fadeInRight">
              {submitted && (
                <div role="alert" className="alert alert-success alert-colored">
                  {t('development.form.success_message', 'Mensagem enviada, obrigado')}
                </div>
              )}
              {formError && (
                <div role="alert" className="alert alert-danger alert-colored">
                  {formError}
                </div>
              )}
              <button
                className="btn btn-color"
                type="submit"
                disabled={submitted}
                onClick={e => submitForm(e)}
              >
                {t('development.form.label_submit', 'Enviar mensagem')}
              </button>
            </div>
          </form>
        </div>
      </div>

      <section className={'grey-bg last-section ' + styles.pricing}>
        <div className="container">
          <div className="section-content">
            <div className={'row ' + styles.pricingSection}>
              <div className="col-md-3 col-sm-6" data-animation="fadeInUp">
                <div className={styles.pricingTable}>
                  <div className={styles.pricingHead}>
                    <h4>{t('development.5_hours', '5 horas')}</h4>
                  </div>
                  <div className={styles.price}>
                    <h2>
                      40€<span> / h</span>
                    </h2>
                  </div>
                  <ul className={styles.features}>
                    <li>
                      <span>
                        {t(
                          'development.pack.5_hours_description',
                          'Ideal para pequenas alterações e correções de bugs',
                        )}
                      </span>
                    </li>
                    <li>
                      <span>
                        {t('development.pack.label_validity', 'Validade da compra')}:{' '}
                        <strong>{t('development.pack.validity_1_year', '1 ano')}</strong>
                      </span>
                    </li>
                    <li>
                      <span>
                        {t('development.pack.label_value', 'Valor total')}: <strong>200€</strong>{' '}
                      </span>
                    </li>
                  </ul>
                  <div className={styles.pricingFooter}>
                    <a
                      id="hours5-button"
                      data-fancybox=""
                      data-src="#hidden-content"
                      className="btn btn-color btn-sm btn-shadow"
                      onClick={() => {
                        setSelectedPlan('5');
                      }}
                    >
                      {t('development.pack.label_buy', 'Comprar')}
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-md-3 col-sm-6" data-animation="fadeInUp">
                <div className={styles.pricingTable}>
                  <div className={styles.pricingHead}>
                    <h4>{t('development.20_hours', '20 horas')}</h4>
                  </div>
                  <div className={styles.price}>
                    <h2>
                      35€<span> / h</span>
                    </h2>
                  </div>
                  <ul className={styles.features}>
                    <li>
                      <span>
                        {t(
                          'development.pack.20_hours_description',
                          'Ideal para alterações médias e pequenas correções de bugs periódicas',
                        )}
                      </span>
                    </li>
                    <li>
                      <span>
                        {t('development.pack.label_validity', 'Validade da compra')}:{' '}
                        <strong>{t('development.pack.validity_1_year', '1 ano')}</strong>{' '}
                      </span>
                    </li>
                    <li>
                      <span>
                        {t('development.pack.label_value', 'Valor total')}: <strong>700€</strong>{' '}
                      </span>
                    </li>
                  </ul>
                  <div className={styles.pricingFooter}>
                    <a
                      id="hours20-button"
                      className="btn btn-color btn-sm btn-shadow"
                      data-fancybox=""
                      data-src="#hidden-content"
                      onClick={() => {
                        setSelectedPlan('20');
                      }}
                    >
                      {t('development.pack.label_buy', 'Comprar')}
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-md-3 col-sm-6" data-animation="fadeInUp">
                <div className={styles.pricingTable + ' ' + styles.featured}>
                  <span className={styles.featuredLabel}>
                    {t('development.pack.featured_label', 'Recomendado')}
                  </span>
                  <div className={styles.pricingHead}>
                    <h4>{t('development.50_hours', '50 horas')}</h4>
                  </div>
                  <div className={styles.price}>
                    <h2>
                      30€
                      <span>
                        <strong> / h</strong>
                      </span>
                    </h2>
                  </div>
                  <ul className={styles.features}>
                    <li>
                      <span>
                        {t(
                          'development.pack.50_hours_description',
                          'Ideal para alterações consideráveis e para uma manutenção a médio prazo',
                        )}
                      </span>
                    </li>
                    <li>
                      <span>
                        {t('development.pack.label_validity', 'Validade da compra')}:{' '}
                        <strong>{t('development.pack.validity_1_year', '1 ano')}</strong>{' '}
                      </span>
                    </li>
                    <li>
                      <span>
                        {t('development.pack.label_value', 'Valor total')}: <strong>1500€</strong>{' '}
                      </span>
                    </li>
                  </ul>
                  <div className={styles.pricingFooter}>
                    <a
                      id="hours50-button"
                      className="btn btn-color btn-sm btn-shadow"
                      data-fancybox=""
                      data-src="#hidden-content"
                      onClick={() => {
                        setSelectedPlan('50');
                      }}
                    >
                      {t('development.pack.label_buy', 'Comprar')}
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-md-3 col-sm-6" data-animation="fadeInUp">
                <div className={styles.pricingTable}>
                  <div className={styles.pricingHead}>
                    <h4>{t('development.100_hours', '100 horas')}</h4>
                  </div>
                  <div className={styles.price}>
                    <h2>
                      27.5€<span> / h</span>
                    </h2>
                  </div>
                  <ul className={styles.features}>
                    <li>
                      <span>
                        {t(
                          'development.pack.100_hours_description',
                          'Ideal para grandes alterações e para uma manutenção a longo prazo',
                        )}
                      </span>
                    </li>
                    <li>
                      <span>
                        {t('development.pack.label_validity', 'Validade da compra')}:{' '}
                        <strong>{t('development.pack.validity_1_year', '1 ano')}</strong>{' '}
                      </span>
                    </li>
                    <li>
                      <span>
                        {t('development.pack.label_value', 'Valor total')}: <strong>2750€</strong>{' '}
                      </span>
                    </li>
                  </ul>
                  <div className={styles.pricingFooter}>
                    <a
                      id="hours100-button"
                      className="btn btn-color btn-sm btn-shadow"
                      data-fancybox=""
                      data-src="#hidden-content"
                      onClick={() => {
                        setSelectedPlan('100');
                      }}
                    >
                      {t('development.pack.label_buy', 'Comprar')}
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={'title text-center ' + styles.pricingCoda}>
          <h6 className={styles.pricingIvaNotice}>
            {t(
              'development.vat_notice',
              'A todos os valores apresentados acresce o IVA à taxa legal em vigor.',
            )}
          </h6>
          <h3 className={styles.customSolution}>
            {t(
              'development.custom_solution_notice',
              'Não encontra uma solução adequada? Temos soluções personalizadas.',
            )}
          </h3>
          <div className={styles.pricingTalkToUsButton}>
            <a
              id="personalizado-button"
              className="btn btn-color btn-sm btn-shadow"
              data-fancybox
              data-src="#hidden-content"
              onClick={() => {
                setSelectedPlan('personalizado');
              }}
            >
              {t('development.label_talk_to_us_btn', 'Fale connosco')}
            </a>
          </div>
        </div>
      </section>

      <ImageAndTextSection
        title={t('development.our_goal.title', 'O nosso objectivo')}
        imageFluid={data.ourGoal.childImageSharp.fluid}
      >
        <Trans
          i18nKey="development.our_goal.body"
          defaults="Não há nada pior que pagar pela solução certa para o problema errado.<0></0>A nossa equipa sabe que evitar isso implica boa comunicação com o cliente de forma a entender o seu problema, uma análise cuidada das soluções para encontrar a que melhor o resolve, a escolha das tecnologias adequadas à solução encontrada, uma implementação com especial atenção à qualidade e performance do código, e finalmente um processo exaustivo de testes para garantir que o problema foi efectivamente resolvido.<1></1>Porque o nosso objectivo é simples: resolver o seu problema e resolvê-lo bem."
          components={[<br key="br1" />, <br key="br2" />]}
        />
      </ImageAndTextSection>

      <ImageAndTextSection
        dark
        imageOnRightSide
        title={t('development.our_stack.title', 'As nossas tecnologias')}
        imageFluid={data.ourStack.childImageSharp.fluid}
      >
        <Trans
          i18nKey="development.our_stack.body"
          defaults="A nossa stack de eleição assenta em <0>React</0> no frontend, <1>Node.js</1> no backend e <2>GraphQL</2> como linguagem da API.<3></3>Sempre que possível usamos <4>Gatsby</4> e a arquitectura <5>JAMStack</5> para os nossos sites e <6>Sanity</6> para os nossos CMSs. <7></7>E onde acaba a nossa experiência começa a dos nossos parceiros, não deixe de nos contactar por não encontrar a sua tecnologia acima."
          components={[
            <a key="a0" href="https://reactjs.org/" target="_blank" rel="noreferrer"></a>,
            <a key="a1" href="https://nodejs.org/" target="_blank" rel="noreferrer"></a>,
            <a key="a2" href="https://graphql.org/" target="_blank" rel="noreferrer"></a>,
            <br key="br0" />,
            <a key="a3" href="https://www.gatsbyjs.org/" target="_blank" rel="noreferrer"></a>,
            <a key="a4" href="https://jamstack.org/" target="_blank" rel="noreferrer"></a>,
            <a key="a5" href="https://www.sanity.io/" target="_blank" rel="noreferrer"></a>,
            <br key="br1" />,
          ]}
        />
      </ImageAndTextSection>

      <ImageAndTextSection
        title={t('development.our_terms.title', 'Os nossos termos')}
        imageFluid={data.ourTerms.childImageSharp.fluid}
      >
        <ul>
          <Trans
            i18nKey="development.our_terms.body"
            defaults="<0>As horas compradas podem ser utilizadas a qualquer altura durante o período especificado em cada um dos pacotes (a contar a partir da data da compra).</0><1>O consumo de horas, por tarefa, será reportado ao cliente e as horas inutilizadas continuarão disponíveis durante o período remanescente.</1><2>Todas as tarefas terão início até dois dias úteis após terem sido requeridas pelo cliente.</2><3>Duração mínima das tarefas: 1 hora.</3><4>A aquisição do pacote de horas pode estar sujeito à disponibilidade.</4>"
            components={[
              <li key="li1"></li>,
              <li key="li2"></li>,
              <li key="li3"></li>,
              <li key="li4"></li>,
              <li key="li5"></li>,
            ]}
          />
        </ul>
      </ImageAndTextSection>
    </Layout>
  );
};

export default withI18n(DevelopmentPage);

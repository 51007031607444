import React from 'react';
import { FluidObject } from 'gatsby-image';
import Img from 'gatsby-image/withIEPolyfill';

import styles from './ImageAndTextSection.module.scss';
import BackgroundImage from 'gatsby-background-image';

interface ImageAndTextSectionProps {
  title: string;
  children: React.ReactNode;
  imageFluid: FluidObject;
  dark?: boolean;
  imageOnRightSide?: boolean;
}

const ImageAndTextSection = ({
  title,
  children,
  imageFluid,
  dark = false,
  imageOnRightSide = false,
}: ImageAndTextSectionProps): React.ReactElement => {
  return (
    <BackgroundImage
      Tag="section"
      fluid={[
        dark
          ? 'linear-gradient(rgba(0, 0, 0, 0.85), rgba(0, 0, 0, 0.85))'
          : 'linear-gradient(rgba(9, 63, 163, 0.9), rgba(9, 63, 163, 0.9))',
        imageFluid,
      ]}
      className={'grey-bg ' + styles.container + (dark ? ' ' + styles.dark : ' ' + styles.light)}
    >
      <div className="container">
        <div className="row row-flex flex-center">
          {!imageOnRightSide && (
            <div className="col-md-5 ">
              <Img
                className={'box-shadow-big animated fadeInLeft ' + styles.image}
                fluid={imageFluid}
                alt=""
                data-animation="fadeInLeft"
              />
            </div>
          )}
          <div className={'col-md-5' + (imageOnRightSide ? '' : ' col-md-offset-1')}>
            <div
              className={'title animated ' + (imageOnRightSide ? 'fadeInLeft' : 'fadeInRight')}
              data-animation={imageOnRightSide ? 'fadeInLeft' : 'fadeInRight'}
            >
              <h2 className="fw-400">{title}</h2>
              <h4>{children}</h4>
            </div>
          </div>
          {imageOnRightSide && (
            <div className="col-md-5 col-md-offset-1">
              <Img
                className={'box-shadow-big animated fadeInRight ' + styles.image}
                fluid={imageFluid}
                alt=""
                data-animation="fadeInRight"
              />
            </div>
          )}
        </div>
      </div>
    </BackgroundImage>
  );
};

export default ImageAndTextSection;
